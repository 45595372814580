import { LitElement, css, html, nothing } from 'lit'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { capFirst } from '#js/components/utils'
import { formPost } from '#js/components/http'
import { setLocale } from '#js/components/lit-i18n'

globalThis.dataLayer = globalThis.dataLayer || []

class TechnicalHelpRequest extends LitElement {
  static get properties () {
    return {
      apiUrl: { type: String },
      phone: { type: String },
      errors: { type: Object, attribute: false },
      isFinished: { type: Boolean, attribute: false, default: false },
      isSubmissionDisable: { type: Boolean, attribute: false }
    }
  }

  static styles = css`
    textarea {
      resize: vertical;
      min-height: 200px;
      max-height: 400px;
    }
    .error {
      color: red;
      font-size: 0.8em;
    }
  `

  constructor () {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
    this.errors = {}
  }

  render () {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      ${this.isFinished ? this.renderSuccessMessage() : this.renderContent()}
    `
  }

  renderSuccessMessage () {
    return html`
      <p>
        ${msg(str`Thank you for reporting a technical problem. We will get in touch with you as soon as possible to find a solution.`)}
      </p>
    `
  }

  renderContent () {
    return html`
      <div class="column">
        <small class="subtitle">
          ${msg(str`Please describe the technical problem so that our service team can help you.`)}
        </small>
        <form class="material" @submit="${this.handleSubmit}" submit>
          <fieldset>
            <p>
              <input
                type="text"
                name="phone"
                placeholder="${capFirst(msg(str`enter your phone number`))}"
                .value="${this.phone}"
                @input="${(e) => { this.errors = {}; this.isSubmissionDisable = false }}"
                required
              >
              <label for="phone">${capFirst(msg(str`phone`))}:</label>
              ${this.renderError('phone')}
            </p>
            <p>
              <textarea
                name="description"
                @input="${(e) => { this.errors = {}; this.isSubmissionDisable = false }}"
                required
              ></textarea>
              <label for="description">${capFirst(msg(str`technical problem`))}:</label>
              ${this.renderError('description')}
            </p>
          </fieldset>
          <div class="form-footer">
            <button
              type="submit"
              class="button button--filled"
              ?disabled="${Object.keys(this.errors).length > 0 || this.isSubmissionDisable}">
              ${capFirst(msg(str`report`))}
            </button>
          </div>
        </form>
      </div>
    `
  }

  async handleSubmit (event) {
    event.preventDefault()
    this.isSubmissionDisable = true

    const formData = new FormData(event.target)
    const data = {}

    for (const [key, value] of formData.entries()) {
      data[key] = value
    }

    try {
      const url = new URL(this.apiUrl, window.location.origin)
      const response = await formPost(url, data)

      if (response.ok) {
        this.isFinished = true
      } else {
        const errorData = await response.json()
        this.errors = errorData
      }
    } catch (error) {
      console.error('Technical help request submission failed:', error)
    }
  }

  renderError (field) {
    if (!this.errors || !this.errors[field]) {
      return nothing
    } else {
      return html`<div class="error">${this.errors[field].join(', ')}</div>`
    }
  }
}

window.customElements.define('technical-help-request', TechnicalHelpRequest)
